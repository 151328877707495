



















import { mapActions, mapGetters } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { BModal } from 'bootstrap-vue'

@Component({
    components: {
        'b-modal': BModal
    },
    computed: {
        ...mapGetters('dossieracademique', ['dossierSelectionSelectSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id'])

    },
    methods: {
        ...mapActions('users', ['saveComment'])
    }
})

export default class CommentaireIntervenant extends Vue {
    @Prop() show?: boolean;
    @Prop() user?: any;

    Ability = Ability

    @Watch('user')
    setCommentaire () {
        if (this.user.comment) {
            this.commentaire = this.user.comment.body
        }
    }

    commentaire = ''

    // Annulation de la prise de décision
    reset (retour: any) {
        this.$emit('close', retour)
        this.commentaire = ''
    }

    // Enregistre le commentaire
    saveCommentaire () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement du commentaire ...', infosToaster)
        this.$store.dispatch('user/saveComment', { user_id: this.$props.user.id, payload: { body: this.commentaire } })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.reset(this.commentaire)
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}


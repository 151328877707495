





























































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatDate } from '@/utils/helpers'
import Vue from 'vue'
import { Prop, Watch, Component } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { mapGetters, mapState } from 'vuex'
import AffichageCandidatsIncompatibles from '@/components/GestionIncompatibles/AffichageCandidatsIncompatibles.vue'
import RechercheParEtablissement from '@/components/GestionIncompatibles/RechercheParEtablissement.vue'
import RechercheParCandidat from '@/components/GestionIncompatibles/RechercheParCandidat.vue'
import { RejectSubmitIncomp, ValidatedSubmissionIncomp } from '@/types/CandidatIncompatible'
import { BModal } from 'bootstrap-vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('candidatIncompatible', ['listIsLoading', 'listeCandidatsIncompatiblesGroupee', 'listIsLoading', 'selectedExaminateur', 'get_submitted_at', 'get_validated_at', 'incompatibleIsRejected'])
    },
    components: {
        'affichage-incomp': AffichageCandidatsIncompatibles,
        RechercheParEtablissement,
        RechercheParCandidat,
        BModal
    }
})

export default class PopupGestionIncompatibles extends Vue {
    @Prop() openModal? : any

    candidatsGroupeeParEtablissements = []
    examinateurSelectionne: any       = {}
    examinateurSide                   = true
    showMessageRejetIncompatible      = false
    componentView                     = 'affichage_candidats_incompatibles'
    messageRejet                      = ''
    ORAL_PREPA_MANAGE                 = Ability.ORAL_PREPA_MANAGE
    ORAL_PREPA_VIEW                   = Ability.ORAL_PREPA_VIEW
    isLoading                         = this.$store.getters['candidatIncompatible/listIsLoading']
    isSubmitted                       = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
    isValidated                       = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
    isRejected                        = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
    comment_reject                    = this.$store.state.candidatIncompatible.comment_reject
    submitted_at                      = this.$store.getters['candidatIncompatible/get_submitted_at']
    validated_at                      = this.$store.getters['candidatIncompatible/get_validated_at']

    /**
     * @description Récupération des données à l'ouverture de la modale
     * @returns {Promise<void>}
     */
    @Watch('openModal', { immediate: true })
    async watchOpenModal(): Promise<void> {
        if (this.$props.openModal === true) {
            await this.getIncompatible()
        } else {
            this.closeModal()
            this.reset_all()
        }
    }

    /**
     * @description Mise à jour de l'état de la soumission
     * @returns {void}
     */
    @Watch('selectedExaminateur')
    setSubmission(): void {
        this.reset_all()
        const params = {
            user_id: this.$store.getters['candidatIncompatible/selectedExaminateur']
        }
        if (params.user_id) {
            this.$store.dispatch('candidatIncompatible/GET_STATE_SUBMISSION', params)
        }
    }

    /**
     * @description Mise à jour de la soumission
     * @returns {void}
     */
    @Watch('get_submitted_at')
    watchSubmit(): void {
        this.isSubmitted    = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
        this.isValidated    = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
        this.isRejected     = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
        this.submitted_at   = this.$store.getters['candidatIncompatible/get_submitted_at']
        this.comment_reject = this.$store.state.candidatIncompatible.comment_reject
    }

    /**
     * @description Mise à jour de la validation
     * @returns {void}
     */
    @Watch('get_validated_at')
    watchValidated(): void {
        this.isSubmitted    = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
        this.isValidated    = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
        this.isRejected     = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
        this.comment_reject = this.$store.state.candidatIncompatible.comment_reject
        this.validated_at   = this.$store.getters['candidatIncompatible/get_validated_at']
    }

    /**
     * @description Mise à jour des rejets
     * @returns {void}
     */
    @Watch('incompatibleIsRejected')
    watchRejected() {
        this.isSubmitted    = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
        this.isValidated    = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
        this.isRejected     = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
        this.validated_at   = this.$store.getters['candidatIncompatible/get_validated_at']
        this.comment_reject = this.$store.state.candidatIncompatible.comment_reject
    }

    /**
     * @description Mise à jour de la liste des candidats incompatibles
     * @returns {void}
     */
    @Watch('listeCandidatsIncompatiblesGroupee')
    getListeCandidatsIncompatibles(): void {
        this.candidatsGroupeeParEtablissements = this.$store.getters['candidatIncompatible/listeCandidatsIncompatiblesGroupee']
    }

    /**
     * @description Mise à jour de l'état de chargement
     * @returns {void}
     */
    @Watch('listIsLoading')
    watchLoading() {
        this.isLoading = this.$store.getters['candidatIncompatible/listIsLoading']
    }

    /**
     * @description Changement de la vue du composant
     * @param {string} classComponentName - Nom de la classe du composant
     * @returns {void}
     */
    changeComponentView(classComponentName: string): void {
        this.componentView = classComponentName
    }

    /**
     * @description Fermeture de la modale
     * @returns {void}
     */
    closeModal(): void {
        if (this.$props.openModal === true) {
            this.$emit('close')
            this.showMessageRejetIncompatible = false
        }
    }

    /**
     * @description Récupération des candidats incompatibles
     * @returns {Promise<void>}
     */
    async getIncompatible(): Promise<void> {
        this.examinateurSelectionne = this.$store.state.candidatIncompatible.examinateurSelectionne
        await this.$store.dispatch('candidatIncompatible/getCandidatsIncompatibles', { user_id: this.examinateurSelectionne.id })
    }

    /**
     * @description Message d'invalidation de la sélection
     * @returns {void}
     */
    rejectSelection(): void {
        this.showMessageRejetIncompatible = true
    }

    /**
     * @description Annulation de soumission
     * @returns {void}
     */
    cancelRejet(): void {
        this.messageRejet = ''
        this.showMessageRejetIncompatible = false
    }

    /**
     * @description Soumission de la liste des candidats incompatibles
     * @returns {void}
     */
    submitIncompatible(): void {
        const params = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            incompatibilites_submit : 1
        }

        const idInfo = 't_info_' + Math.random()

        this.$store.dispatch('candidatIncompatible/SUBMIT_INCOMP', params)
            .then(async () => {
                // Récupération des nouvelles données stats
                await this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Soumission enregistrée !', succesToaster)
                this.updateExamData()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Validation de la soumission
     * @returns {void}
     */
    validateSubmit(): void {
        const params: ValidatedSubmissionIncomp = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            incompatibilites_validate : 1
        }

        const idInfo = 't_info_' + Math.random()

        this.$store.dispatch('candidatIncompatible/VALIDATION_INCOMP', params)
            .then(async () => {
                // Récupération des nouvelles données stats
                await this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Validation de la soumission enregistrée !', succesToaster)
                this.updateExamData()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Rejet de la soumission et envois du message à l'examinateur
     * @returns {void}
     */
    rejectSubmission(): void {
        const params: RejectSubmitIncomp = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            incompatibilites_submit : 0,
            commentaire_rejet : this.messageRejet
        }
        const idInfo = 't_info_' + Math.random()

        this.$store.dispatch('candidatIncompatible/REJECT_INCOMP', params)
            .then(async () => {
                // Récupération des nouvelles données stats
                await this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Annulation de la soumission enregistrée !', succesToaster)
                this.messageRejet = ''
                this.updateExamData()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.showMessageRejetIncompatible = false
            })
    }

    /**
     * @description Invalidation de la soumission
     * @returns {void}
     */
    invalidIncomp(): void {
        const params = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            incompatibilites_validate: 0
        }

        const idInfo = 't_info_' + Math.random()

        this.$store.dispatch('candidatIncompatible/INVALID_INCOMP', params)
            .then(async () => {
                // Récupération des nouvelles données stats
                await this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Annulation de la soumission enregistrée !', succesToaster)
                this.updateExamData()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Réinitialisation des variables
     * @returns {void}
     */
    reset_all(): void {
        this.componentView = 'affichage_candidats_incompatibles'
        this.updateExamData()
        this.cancelRejet()
        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.$store.commit('candidatIncompatible/RESET_SELECTED_EXAMINATEUR')
        this.$store.commit('candidatIncompatible/RESET_STATE_SUBMISSION')
        this.$store.commit('candidatIncompatible/RESET_LISTE_INCOMPATIBLE')
        this.isSubmitted    = null
        this.isValidated    = null
        this.isRejected     = null
        this.comment_reject = null
        this.submitted_at   = null
        this.validated_at   = null
    }

    /**
     * @description Retourne une classe css selon l'état de validation des infos
     * @returns {string} Classe css
     */
    getClassBarreBoutons(): string {
        if (this.$store.getters['candidatIncompatible/incompatibleIsSubmitted'] && !this.$store.getters['candidatIncompatible/incompatibleIsValidated']) {
            return 'barre_soumis'
        } else if (this.$store.getters['candidatIncompatible/incompatibleIsSubmitted'] && this.$store.getters['candidatIncompatible/incompatibleIsValidated']) {
            return 'barre_valide'
        } else if (this.$store.getters['candidatIncompatible/incompatibleIsRejected']) {
            return 'barre_rejet'
        }
        return 'barre_default'
    }

    /**
     * @description Formatage de la date
     * @param {Date} date - Date à formater
     * @returns {string | undefined} - Date formatée
     */
    formatTheDate(date: Date): string | undefined {
        return formatDate(date)
    }

    /**
     * @description Mise à jour des données de l'examen
     * @returns {void}
     */
    updateExamData(): void {
        const examinateur       = this.$store.getters.candidatIncompatible.examinateurSelectionne
        const stateSubmitted    = this.$store.state.candidatIncompatible.submitted_at
        const stateValidated    = this.$store.state.candidatIncompatible.validated_at
        const stateCommentRejet = this.$store.state.candidatIncompatible.comment_reject
        const listeExaminateur  = this.$store.state.examinateur.listeExaminateurs

        examinateur.infosessionuser = {
            incompatibilites_submitted_at: stateSubmitted,
            incompatibilites_validated_at: stateValidated,
            commentaire_rejet: {
                incompatibilites: stateCommentRejet
            }
        }
        examinateur.incompatibilites_count = this.$store.getters['candidatIncompatible/get_number_of_incomp']
        const index = listeExaminateur.map((item: any) => item.id).indexOf(examinateur.id)
        Vue.set(listeExaminateur, index, examinateur)
        this.$store.commit('examinateur/SET_EXAMINATEURS', listeExaminateur)
    }
}


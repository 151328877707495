




























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState }       from 'vuex'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'
import { isObject }         from '@/utils/helpers'
import { TypePassation } from '@/types/Epreuve'
import MessageIndication from '@/components/MessageIndication.vue'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('session', ['sessions', 'sessionSelect']),
        ...mapState('disponibilite', ['error_dispos']),
        ...mapGetters('disponibilite', ['get_priorite_next', 'get_priorite_total'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        MessageIndication,
        ErrorDisplay
    }
})

export default class DefinitionMinima extends Vue {
    @Prop() openModal? : boolean

    dico_epreuves: any = []
    nb_series = 0
    TypePassation = TypePassation
    MessageIndicationType = MessageIndicationType

    @Watch('openModal')
    initOpenModal () {
        if (this.$props.openModal) {
            this.initDatas()
        }
    }

    /** Fermeture dupopup de publication */
    closeModal() {
        // Reinit error
        this.$store.state.disponibilite.error_dispos = null
        this.$emit('close')
    }

    /** Check si l'erreur récupérée est un objet */
    isObjectLocal (value: any) {
        return isObject(value)
    }

    /** Initialisation des données */
    initDatas () {
        // Reinit error
        this.$store.state.disponibilite.error_dispos = null

        // Chargement des séries
        this.$store.dispatch('serie/getSeries').then(() => {
            this.nb_series = this.$store.state.serie.series.length
        })

        // Chargement des épreuves de corrections filtrées sur session
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { params: null }).then(() => {
            const filter_by = [TypePassation.TYPE_PASSATION_ORAL, TypePassation.TYPE_PASSATION_TP]
            this.dico_epreuves = this.$store.getters['epreuve/epreuves_corrections_filter'](filter_by)
        })
    }

    /** Save minimas */
    saveMinimas () {
        // Reinit error
        this.$store.state.disponibilite.error_dispos = null

        // Création du payload
        const payload_epreuves = []

        for (const e in this.dico_epreuves) {
            payload_epreuves.push({ id: this.dico_epreuves[e].id, sceances_serie_min: this.dico_epreuves[e].sceances_serie_min })
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload = {
            epreuves: payload_epreuves
        }

        this.$store.dispatch('disponibilite/saveMinimas', { payload: payload })
            .then(() => {
                this.$emit('close', true)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Retourne le calcul de la priorité 2 */
    get_priorite_next_func (seances: number) {
        return this.$store.getters['disponibilite/get_priorite_next'](seances)
    }

    /** Retourne le calcul des totaux d'une priorite en fonction du nb de series */
    get_priorite_total_func (seances: number) {
        return this.$store.getters['disponibilite/get_priorite_total'](seances, this.nb_series)
    }

    /** Retourne le calcul des totaux d'une priorite 2 */
    get_priorite_total_next_func (seances: number) {
        const total_priorite = this.$store.getters['disponibilite/get_priorite_total'](seances, this.nb_series)
        return this.$store.getters['disponibilite/get_priorite_next'](total_priorite)
    }

    beforeMount () {
        this.initDatas()
    }
}
















































import { Vue, Component, Prop, Watch }                              from 'vue-property-decorator'
import { mapGetters, mapState }                                               from 'vuex'
import { FontAwesomeIcon }                                          from '@fortawesome/vue-fontawesome'
import { diffTwoDatesInMinutes, formatDateYYYYMMDD, isObject, formatDayHourZDateFromString, formatEndOfDayHourZDateFromString }      from '@/utils/helpers'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('examinateur', ['listeExaminateurs', 'isLoading', 'totalRows', 'lastPage', 'totalPage', 'selectedExaminateur']),
        ...mapGetters('session', ['sessions', 'sessionSelect']),
        ...mapGetters('session', ['error'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PublicationDisponibilites extends Vue {
    @Prop() openModal? : boolean

    selectedDate = {
        userSelectDateType: 'closeInput',
        dateBetween: {
            date_start: '',
            date_end: ''
        },
        onlyEndDate: {
            date_end: ''
        }
    }

    betweenError = ''
    onlyEndDateError = ''

    @Watch('sessionSelect')
    initFromSessionSelect() {
        this.initDates()
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }


    /** Fermeture du popup de publication */
    closeModal() {
        this.$store.commit('disponibilite/RESET_STATE')
        this.$store.state.session.error = null
        this.betweenError = ''
        this.onlyEndDateError = ''
        this.$emit('close')
    }

    /** Check si l'erreur récupérée est un objet */
    isObjectLocal (value: any) {
        return isObject(value)
    }

    /** Check les dates avant enregistrement */
    checkInputValidation () {
        this.$store.state.session.error = null
        this.betweenError = ''
        this.onlyEndDateError = ''
        let valid = false
        const dateNow = new Date(Date.now()).toISOString()

        switch (this.selectedDate.userSelectDateType) {
            case 'between':
                if (diffTwoDatesInMinutes(this.selectedDate.dateBetween.date_start, this.selectedDate.dateBetween.date_end) < 0) {
                    this.betweenError = 'La date d\'ouverture doit être antérieure ou égale à la date de fermeture.'
                } else if (this.selectedDate.dateBetween.date_start === '' || this.selectedDate.dateBetween.date_end === '') {
                    this.betweenError = 'La date d\'ouverture et la date de fermeture doivent être saisies.'
                } else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), this.selectedDate.dateBetween.date_start) < 0) {
                    this.betweenError = 'La date d\'ouverture doit être postérieure ou égale à la date d\'aujourd\'hui.'
                }  else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), this.selectedDate.dateBetween.date_end) < 0) {
                    this.betweenError = 'La date de fermeture doit être postérieure ou égale à la date d\'aujourd\'hui.'
                } else {
                    this.betweenError = ''
                    valid = true
                }
                break
            case 'onlyEndDate':
                if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), this.selectedDate.onlyEndDate.date_end) < 0) {
                    this.onlyEndDateError = 'La date de saisie doit être postérieure ou égale à la date d\'aujourd\'hui.'
                } else if (this.selectedDate.onlyEndDate.date_end === '') {
                    this.onlyEndDateError = 'La date doit être saisie.'
                } else {
                    this.onlyEndDateError = ''
                    valid = true
                }
                break
            case 'closeInput':
                valid = true
                break
            default:
                break
        }

        if (valid) {
            this.validerDate()
        }
    }

    /** Validation des dates saisies avant enregistrement */
    validerDate() {
        let params = {}
        const id = this.$store.getters['session/sessionSelect'].id

        switch (this.selectedDate.userSelectDateType) {
            case 'between':
                params = {
                    session_id:               id,
                    disponibilites:           1,
                    disponibilites_start_at:  this.convertDateToIso(this.selectedDate.dateBetween.date_start),
                    disponibilites_end_at:    this.convertDateToIso(formatEndOfDayHourZDateFromString(this.selectedDate.dateBetween.date_end)) // La journée de fermeture est incluse
                }
                break
            case 'onlyEndDate':
                params = {
                    session_id:               id,
                    disponibilites:           1,
                    disponibilites_start_at:  null,
                    disponibilites_end_at:    this.convertDateToIso(formatEndOfDayHourZDateFromString(this.selectedDate.onlyEndDate.date_end)) // La journée de fermeture est incluse

                }
                break
            case 'closeInput':
                params = {
                    session_id:               id,
                    disponibilites:           1,
                    disponibilites_start_at:  null,
                    disponibilites_end_at:    null
                }
                break
            default:
                break
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('session/updateSessionPlages', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                // Reload de la session active
                this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] }).then(() => {
                    // Fermeture de la popup
                    this.closeModal()
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Initialisation des dates */
    initDates() {
        const activeSession = this.$store.getters['session/sessionSelect']

        if (activeSession && activeSession.disponibilites_end_at !== null && activeSession.disponibilites_start_at !== null) {
            this.selectedDate.dateBetween.date_start = formatDateYYYYMMDD(activeSession.disponibilites_start_at)
            this.selectedDate.dateBetween.date_end = formatDateYYYYMMDD(activeSession.disponibilites_end_at)
            this.selectedDate.onlyEndDate.date_end = ''
            this.selectedDate.userSelectDateType = 'between'
        } else if (activeSession && activeSession.disponibilites_end_at !== null) {
            this.selectedDate.onlyEndDate.date_end = formatDateYYYYMMDD(activeSession.disponibilites_end_at)
            this.selectedDate.dateBetween.date_start = ''
            this.selectedDate.dateBetween.date_end = ''
            this.selectedDate.userSelectDateType = 'onlyEndDate'
        }
    }

    /** Formate une valeur string de type 'YYYY-MM-DD HH:mm:ss' en valeur moment YYYY-MM-DDTHH:mm:ss[Z] */
    convertDateToIso = (dateString: string | undefined) => {
        if (dateString) {
            return formatDayHourZDateFromString(dateString)
        }
    }

    load () {
        this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] }).then(() => {
            this.$store.commit('session/SET_SESSION_SELECT', this.$store.getters['session/sessionSelect'])
        })
    }

    beforeMount() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}

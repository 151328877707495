

















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PublicationDisponibilites from '@/components/GestionExaminateurs/Disponibilites/PublicationDisponibilites.vue'
import DefinitionMinima from '@/components/GestionExaminateurs/Disponibilites/DefinitionMinima.vue'
import { formatDateSinTime, checkIcone, dateDuJourFoDocument, getFileNameFromHeader } from '@/utils/helpers'
import CommentaireIntervenant from '@/components/SelectionRH/CommentaireIntervenant.vue'
import PopupEditionExaminateur from '@/components/GestionExaminateurs/PopupEditionExaminateur.vue'
import { TypePassation } from '@/types/Epreuve'
import { EnsembleInterface, TypeEnsemble } from '@/types/Ensemble'
import { EntityType, getEntityTypeShortFormat, PosteType } from '@/types/Poste'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'
import { BModal } from 'bootstrap-vue'

/**
 * Composant contenant l'ensemble des candidats
*/
@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('session', ['sessionActive']),
        ...mapGetters('disponibilite', ['candidats', 'dispos_is_open']),
        ...mapGetters('examinateur', ['listeExaminateurs', 'isLoading', 'totalRows', 'lastPage', 'totalPage', 'selectedExaminateur']),
        ...mapState('examinateur', ['meta']),
        ...mapGetters('serie', ['series']),
        ...mapGetters('concour', ['concourById']),
        ...mapState('matiere', ['matieres']),
        ...mapState('ensemble', ['ensembles']),
        ...mapState('sessionuser', ['statsValidation', 'error_session_user'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        PublicationDisponibilites,
        DefinitionMinima,
        CommentaireIntervenant,
        PopupEditionExaminateur,
        PopUpInfoIntervenant,
        ErrorDisplay,
        'b-modal': BModal
    }
})

export default class GestionDisponibilites extends Vue {
    showGestionMinima?:      boolean = false
    showPublicationDispo?:   boolean = false
    showEditExaminateur? :   boolean = false

    Ability = Ability
    formatDateSinTime = formatDateSinTime
    chargement = false

    // Ensemble des colonnes du tableau de candidats

    genericfields = [
        { key: 'etatEdit',                  label: '',                              sortable: false,    class: '', type: 'action' },
        // { key: 'name',                      label: 'Nom',                           sortable: true,     class: 'col_min_width', type: 'text' },
        // { key: 'first_name',                label: 'Prénom',                        sortable: true,     class: 'col_min_width', type: 'text' },
        { key: 'name', label: 'Identité', sortable: true, sortDirection: 'asc', class: 'text-start ps-4',  type: 'actionText'  },
        { key: 'ensemble_name',             label: 'Équipe(s)',                        sortable: true,     class: 'col_min_width', type: 'text' },
        { key: 'matiere.id',                label: 'Matière(s)',                       sortable: false,    class: 'col_min_width', type: 'text' },
        { key: 'journeePerso',              label: 'Journée type personnalisée',    sortable: false,    class: 'text-center', type: 'text' },
        { key: 'seances',                   label: 'Séances contractualisées',      sortable: false,    class: 'text-center', type: 'text' },
        { key: 'sceances_serie_min',        label: 'Minimum séances (+10%)',        sortable: false,    class: 'text-center', type: 'text' },
        { key: 'infosessionuser',           label: 'Saisie examinateur',            sortable: true,     class: 'text-center', type: 'text' },
        { key: 'creneaux_count',            label: 'Définies par l\'examinateur',   sortable: false,    class: 'text-center', type: 'text' },
        { key: 'ajustements_validated_at',  label: 'Ajustements',                   sortable: true,     class: 'text-center', type: 'text' },
        { key: 'creneaux_actifs_count',     label: 'Séances pour planification',    sortable: false,    class: 'text-center', type: 'text' },
        { key: 'creneaux_candidats_count',  label: 'Candidats réels',               sortable: true,    class: 'text-center', type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;
    params: any = {
        typePoste: PosteType.TYPE_PEDAGOGIQUE,
        typePassation: TypePassation.TYPE_PASSATION_ORAL,
        typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
        from: 'disponibilites'
    }

    filtres:    any         = []
    dataForTab: Array<any>  = []
    todayDate = Date.now()
    selectedSerieId = null
    select_examinateur = null
    filtreJustInitiated = true
    showPopupParamsExport = false
    ensemble_selected = null
    exportingIsWorking = false

    showModalEditionComment?: boolean = false

    showPopupValidationInvalidationGroupee = false
    nbSubmitConcret                        = 0
    ModeInvalidation                       = false
    export_select = 'export_equipe'

    showModalInfoIntervenant = false
    user_id_temp = 0


    @Watch('listeExaminateurs')
    getExaminateurs() {
        this.initDatas()
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists () {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs').then(() => {
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    /** quand on sélectionne une série ds le select au-dessus du tableau, on relance la construction des colonnes du tableau */
    serieChangeHandler() {
        if (this.selectedSerieId) {
            this.params.serie = this.selectedSerieId
        } else {
            this.params.serie = null
        }
        this.$store.dispatch('examinateur/getExaminateursDisponibilite', this.params)
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        const can = this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE)
        const icone = checkIcone(Ability.ORAL_PREPA_MANAGE, can)

        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            this.dataForTab = []
            for (const result of poData) {
                // Libellé de l'épreuve + minimum scéances
                //const filiereEpreuveDisplayed = result.matiere ? result.matiere.name : '-'
                let filiereEpreuveDisplayed = ''
                const matiere_ids =  this.$store.getters['examinateur/get_examinateur_matiere_ids'](result)
                for(let i = 0; i < matiere_ids.length; i++) {
                    const matiereName = this.$store.getters['matiere/getMatiereById'](matiere_ids[i])?.name
                    if(!filiereEpreuveDisplayed.includes(matiereName)) {
                        if(filiereEpreuveDisplayed !== '') {
                            filiereEpreuveDisplayed += ', '
                        }
                        filiereEpreuveDisplayed += matiereName
                    }
                }

                if(filiereEpreuveDisplayed === '') {
                    filiereEpreuveDisplayed = '-'
                }
                let sceances_serie_min = 0
                let type_passation = null
                let seances_contract = 0

                if (result.ensembles) {
                    for (let k = 0; k < result.ensembles.length; k++) {
                        const ensemble = result.ensembles[k]
                        if (ensemble && ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT && result.affectations.length !== 0) {
                            const epreuve_correction_id_examinateur = result.affectations[0].epreuve_correction_id
                            /*
                            const index = ensemble.groupeEpreuve.epreuves.findIndex((ep: any) => ep.id === epreuve_correction_id_examinateur)
                            // filiereEpreuveDisplayed = result.ensemble.concour.name + '-' + result.ensemble.groupeEpreuve.epreuves[index].name
                            sceances_serie_min = ensemble.groupeEpreuve.epreuves[index].sceances_serie_min
                            type_passation = ensemble.groupeEpreuve.epreuves[index].type_passation
                            seances_contract = result.vacations_count * ensemble.groupeEpreuve.epreuves[index].nb_cand_vacation
                            */
                            const epreuveTemp = ensemble.groupeEpreuve.epreuves.find((ep: any) => ep.id === epreuve_correction_id_examinateur)
                            if (epreuveTemp) {
                                sceances_serie_min += epreuveTemp.sceances_serie_min
                                type_passation = epreuveTemp.type_passation
                                seances_contract = result.vacations_count * epreuveTemp.nb_cand_vacation
                            }
                        } else if (ensemble && ensemble.groupeEpreuve) {
                            let epreuveIdTemp = 0
                            for (let l = 0; l < result.affectations.length; l++) {
                                if (result.affectations[l].ensemble_id) {
                                    if (result.affectations[l].ensemble_id === ensemble.id) {
                                        epreuveIdTemp = result.affectations[l].epreuve_correction_id
                                    }
                                }
                            }
                            for (let i = 0; i < ensemble.groupeEpreuve.epreuves.length; i++) {
                                if (ensemble.groupeEpreuve.epreuves[i].id === epreuveIdTemp) {
                                    for (let j = 0; j < ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                                        sceances_serie_min += ensemble.groupeEpreuve.epreuves[i].sceances_serie_min
                                        type_passation = ensemble.groupeEpreuve.epreuves[i].type_passation
                                        seances_contract = result.vacations_count * ensemble.groupeEpreuve.epreuves[i].nb_cand_vacation
                                    }
                                }
                            }
                        }
                    }
                }

                // Si une série est sélectionnée, on divise les séances contractualisées par le nombre de séries
                if (this.selectedSerieId) {
                    seances_contract = seances_contract / this.$store.state.serie.series.length
                }

                // Si pas de série sélectionnée, on adapte les infos pour toutes les séries
                if (!this.selectedSerieId) {
                    sceances_serie_min = sceances_serie_min * this.$store.state.serie.series.length
                }
                const seances_min_with_next = sceances_serie_min + '  ' + this.$store.getters['disponibilite/get_priorite_next'](sceances_serie_min).libelle

                // Puce Saisie examinateur
                const puce_saisie_examinateur = []
                let class_saisie_examinateur = 'text-tertiary'

                if (result.infosessionuser) {
                    switch (true) {
                        case result.infosessionuser.disponibilites_validated_at !== null:
                            class_saisie_examinateur = 'text-success'
                            break
                        case result.infosessionuser.disponibilites_submitted_at !== null:
                            class_saisie_examinateur = 'text-info'
                            break
                        case result.infosessionuser.commentaire_rejet !== null:
                            if (result.infosessionuser.commentaire_rejet.disponibilites) {
                                class_saisie_examinateur = 'text-danger'
                            }
                            break
                    }
                }

                puce_saisie_examinateur.push({ name:'circle', class: class_saisie_examinateur })

                // Puce journee type personalisé
                const puce_journeeTypePerso = []
                let classjourneeTypePerso = 'text-tertiary'
                if(result.affectations) {
                    for(const affect of result.affectations) {
                        if(affect.h_end !== null || affect.h_end_break !== null ||affect.h_start !== null || affect.h_start_break !== null) {
                            classjourneeTypePerso = 'text-success'
                        }
                    }
                }
                puce_journeeTypePerso.push({ name:'circle', class: classjourneeTypePerso })

                // Puce ajustement
                const puce_ajustements = []
                let class_ajustement_examinateur = 'text-tertiary'
                if (result.infosessionuser) {
                    switch (true) {
                        case result.infosessionuser.ajustements_validated_at !== null:
                            class_ajustement_examinateur = 'text-success'
                            break
                    }
                }
                if (type_passation === TypePassation.TYPE_PASSATION_TP) {
                    puce_ajustements.push({ name:'circle', class: 'text-light' })
                } else {
                    puce_ajustements.push({ name:'circle', class: class_ajustement_examinateur })
                }

                let ensembles = ''

                if (result.ensembles) {
                    for (let k = 0; k < result.ensembles.length; k++) {
                        if (ensembles !== '') {
                            ensembles += ', '
                        }
                        ensembles += result.ensembles[k].name
                    }
                } else if (result.ensemble) {
                    ensembles = result.ensemble ? result.ensemble.name : '-'
                }

                const line = [
                    { label: icone.label,   item: result,                                    type: 'action',  typeAction: 'edit',        class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    // { label: '',            item: result.name,                                  type: 'text',    typeAction: null,          class: '' },
                    // { label: '',            item: result.first_name,                            type: 'text',    typeAction: null,          class: '' },
                    { label: '', item: result.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: result.name + ' ' + result.first_name },
                    // { label: '',            item: result.ensemble ? result.ensemble.name : '-', type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: ensembles, type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: filiereEpreuveDisplayed,                      type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: puce_journeeTypePerso,                             type: 'icons',   typeAction: null,          class: 'text-center' },
                    { label: '',            item: seances_contract,                             type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: seances_min_with_next,                        type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: puce_saisie_examinateur,                      type: 'icons',   typeAction: null,          class: 'text-center' },
                    { label: '',            item: result.creneaux_prio1_count + ' +' + result.creneaux_prio2_count, type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: puce_ajustements,                             type: 'icons',   typeAction: null,          class: 'text-center' },
                    { label: '',            item: type_passation !== TypePassation.TYPE_PASSATION_TP ? result.creneaux_actifs_count : '-',      type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: result.creneaux_candidats_count,   type: 'text',    typeAction: null,          class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const options_matieres = []
        for (const m in this.$store.state.matiere.matieres) {
            options_matieres.push({ index: this.$store.state.matiere.matieres[m].id, name: this.$store.state.matiere.matieres[m].name })
        }

        this.filtres = [
            { libelle: 'Nom',       defautOptionlibelle: 'Rechercher un',   model: 'name',          value: '',                                   datas: '',                  loading: false, options: { type: 'form',      fieldsKey: 'name' }},
            { libelle: 'Équipe',    defautOptionlibelle: 'Rechercher une',  model: 'ensemble_name', value: '', index: 'ensemble_name',           datas: null,                loading: false, options: { type: 'form',      fieldsKey: 'ensemble_name', strict: true } },
            { libelle: 'Matière',   defautOptionlibelle: 'Rechercher une',  model: 'matiere_id',    value: '', index: 'matiere.id',              datas: options_matieres,    loading: false, options: { type: 'deroulant', fieldsKey: 'matiere.id' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const examinateurs_dispos = this.$store.state.examinateur.listeExaminateurs
            let selectedExaminateur    = null

            switch (paParams[0]) {
                case 'edit':
                    selectedExaminateur = examinateurs_dispos.filter((examinateur: any) => examinateur.id === paParams[1].id)[0]
                    if (selectedExaminateur) {
                        this.editDisponibilites(selectedExaminateur)
                    }
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /** Ouvre le popup qui édite les disponibilités d'un examinateur */
    editDisponibilites (item: any) {
        if (this.selectedSerieId) {
            this.selectedSerieId  = null
            this.params.serie = null
            this.$store.dispatch('examinateur/getExaminateursDisponibilite', this.params).then(() => {
                this.editDisponibilitesSuite(item)
            })
        } else {
            this.editDisponibilitesSuite(item)
        }
    }

    editDisponibilitesSuite (item: any) {
        if (item && item.id !== undefined) {
            this.$store.commit('examinateur/SET_EXAMINATEUR_ID', item.id)
            const examinateur_select = this.$store.getters['examinateur/examinateur_select']
            this.$store.commit('candidatIncompatible/SET_EXAMINATEUR_SELECTIONNE', examinateur_select)
            this.showEditExaminateur = true
        }
    }

    /**
     * Initialisation des données examinateurs affichées dans le tableau ainsi que les compteurs
     */
    initDatas () {
        if (this.$store.state.examinateur.listeExaminateurs && this.$store.state.examinateur.listeExaminateurs.length !==  0) {
            this.setDataForGenericTab(this.$store.state.examinateur.listeExaminateurs)
        }
    }

    /**
     * Appel d'une nouvelle page de d'examinateur lors du scroll
     */
    loadHandler (params: any) {
        for (const p in params) {
            this.params[p] = params[p]
        }
        if (this.selectedSerieId) {
            this.params.serie = this.selectedSerieId
        }
        this.$store.dispatch('examinateur/getMoreExaminateursDisponibilite', this.params)
    }


    /**
     * Appel des datas avec un sort en paramètres
     */
    filtreSortHandler (params: any) {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            // Clean les params
            this.params = {
                typePoste: PosteType.TYPE_PEDAGOGIQUE,
                typePassation: TypePassation.TYPE_PASSATION_ORAL,
                typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
                from: 'disponibilites'
            }

            for (const p in params) {
                this.params[p] = params[p]
            }
            if (this.selectedSerieId) {
                this.params.serie = this.selectedSerieId
            }
            this.$store.state.examinateur.listeExaminateurs = []
            this.$store.dispatch('examinateur/getExaminateursDisponibilite', this.params).then(() => {
                this.setDataForGenericTab(this.$store.state.examinateur.listeExaminateurs)
            })
        }
    }

    /** Retourne le message en fonction des dates sur la publication des disponibilités */
    getInfoPublicationDispos () {
        const session_active = this.$store.getters['session/sessionSelect']
        const is_open = this.$store.getters['disponibilite/dispos_is_open'](session_active)
        let message = ''
        let class_text = ''

        if (is_open) {
            // Ouverte & programmée
            if (this.todayDate &&  session_active &&  session_active.disponibilites_start_at) {
                message = 'Disponibilités :  la saisie est ouverte du ' + formatDateSinTime(session_active.disponibilites_start_at) + ' au ' + formatDateSinTime(session_active.disponibilites_end_at) + ' inclus'
                class_text = 'barre_text_valide'
            }

            // Ouverte
            if (session_active &&  session_active.disponibilites_start_at === null && session_active.disponibilites_end_at) {
                message = 'Disponibilités :  la saisie est ouverte jusqu\'au ' + formatDateSinTime(session_active.disponibilites_end_at) + ' inclus'
                class_text = 'barre_text_valide'
            }
        } else {
            // Fermée ou non publiée
            if (session_active && session_active.disponibilites_start_at === null && session_active.disponibilites_end_at === null) {
                message = 'Disponibilités : Saisie fermée'
                class_text = 'barre_text_defaut'
            }
            if (session_active && session_active.disponibilites_start_at === null && session_active.disponibilites_end_at) {
                message = 'La saisie des disponibilités est fermée depuis le ' + formatDateSinTime(session_active.disponibilites_end_at) + ' au soir'
                class_text = 'barre_text_defaut'
            }

            // Fermée & Programmée
            if (this.todayDate &&  session_active &&  session_active.disponibilites_start_at) {
                message = 'Disponibilités : la saisie est programmée du ' + formatDateSinTime(session_active.disponibilites_start_at) + ' au ' + formatDateSinTime(session_active.disponibilites_end_at) + ' inclus'
                class_text = 'barre_text_soumis'
            }
        }

        return { message: message, class_css: class_text }
    }

    /** Ferme la popup des minimas */
    closeMinimas (withSave: boolean) {
        if (withSave) {
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.$bvToast.toast('Enregistrement terminé.', succesToaster)

            this.params =     {
                typePoste: PosteType.TYPE_PEDAGOGIQUE,
                typePassation: TypePassation.TYPE_PASSATION_ORAL,
                typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
                from: 'disponibilites'
            }

            if (this.selectedSerieId) {
                this.params.serie = this.selectedSerieId
            }

            this.$store.dispatch('examinateur/getExaminateursDisponibilite', this.params).then(() => {
                this.setDataForGenericTab(this.$store.state.examinateur.listeExaminateurs)
            })
        }
        this.showGestionMinima = false
    }

    /** Ouvre le popup de sélection d'équipe */
    ensembles_ordered = null
    exportDispos () {
        // Chargement des ensembles
        this.$store.dispatch('ensemble/getEnsembles', { params: null }).then(() => {
            this.ensembles_ordered = this.$store.state.ensemble.ensembles.sort((a: EnsembleInterface, b: EnsembleInterface) => {
                return (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
            })
            this.showPopupParamsExport = true
        })
    }

    /** Export des disponibilites */
    exporterDispos () {
        this.exportingIsWorking = true
        let fileName = 'export_disponibilites'
        if (this.export_select === 'export_equipe') {
            // Récupère le nom de l'équipe pour le nom du fichier
            let ensemble_name = 'toutes_equipes'
            if (this.ensemble_selected) {
                ensemble_name = this.$store.state.ensemble.ensembles.find((e: any) => e.id === this.ensemble_selected).name
                fileName += '_' + ensemble_name
            }
        }

        fileName += '_' + dateDuJourFoDocument() + '.xlsx'

        if (this.export_select === 'export_equipe') {
            this.$store.dispatch('disponibilite/export_equipes', { ensemble_id: this.ensemble_selected })
                .then((response) => {
                    // Logique de téléchargement
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(new Blob([response.data]))
                    link.setAttribute('Download', getFileNameFromHeader(response.headers) || fileName)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.ensemble_selected = null
                    this.export_select = 'export_equipe'
                    this.showPopupParamsExport = false
                })
                .finally(() => {
                    this.exportingIsWorking = false
                })
        } else if (this.export_select === 'export_cand_reel') {
            this.$store.dispatch('disponibilite/export_candidats_reels')
                .then((response) => {
                    // Logique de téléchargement
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(new Blob([response.data]))
                    link.setAttribute('Download', getFileNameFromHeader(response.headers) || fileName)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.ensemble_selected = null
                    this.export_select = 'export_equipe'
                    this.showPopupParamsExport = false
                })
                .finally(() => {
                    this.exportingIsWorking = false
                })
        } else if (this.export_select === 'export_seances_contract') {
            this.$store.dispatch('disponibilite/export_seances_contract')
                .then((response) => {
                    // Logique de téléchargement
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(new Blob([response.data]))
                    link.setAttribute('Download', getFileNameFromHeader(response.headers) || fileName)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.ensemble_selected = null
                    this.export_select = 'export_equipe'
                    this.showPopupParamsExport = false
                })
                .finally(() => {
                    this.exportingIsWorking = false
                })
        }
    }

    /** Ferme ou annule le popup de paramétrage de l'export */
    closePopupExport () {
        this.export_select = 'export_equipe'
        this.ensemble_selected = null
        this.showPopupParamsExport = false
    }

    /** Ouvre la fenêtre de validation groupée des disponibilités */
    openPopupValidationGroupee() {
        this.$store.commit('sessionuser/SET_ERROR', null) // reset de l'erreur potentiellement existante
        this.ModeInvalidation = false
        this.$store.dispatch('sessionuser/getSessionUserStatsvalidation').then(() => {
            this.nbSubmitConcret = this.$store.state.sessionuser.statsValidation.disponibilites_nb_submit - this.$store.state.sessionuser.statsValidation.disponibilites_nb_valides // soumis non encore validés.
            this.showPopupValidationInvalidationGroupee = true
        })
    }

    /** Ouvre la fenêtre d'invalidation groupée des disponibilités */
    openPopupInvalidationGroupee() {
        this.$store.commit('sessionuser/SET_ERROR', null) // reset de l'erreur potentiellement existante
        this.ModeInvalidation = true
        this.showPopupValidationInvalidationGroupee = true
    }


    /** Ferme/Annule la fenêtre de validation groupée des disponibilités */
    closePopupValidationInvalidationGroupee() {
        this.showPopupValidationInvalidationGroupee = false
    }

    /** confirmation de la validation groupée */
    confirmValidationInvalidationGroupee () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        let paramsValidationInvalidationGroupee: {}
        if (this.ModeInvalidation) {
            // Cas de l'invalidation
            paramsValidationInvalidationGroupee = {
                validate: 0
            }
        } else {
            // Cas de la validation
            paramsValidationInvalidationGroupee = {
                validate: 1
            }
        }

        const message_toast_enCours = this.ModeInvalidation ? 'Invalidation en cours ...' : 'Validation en cours ...'
        const message_toast_success = this.ModeInvalidation ? 'Les disponibilités des examinateurs ont été invalidées avec succès !' : 'Les disponibilités des examinateurs ont été validées avec succès !'
        this.$bvToast.toast(message_toast_enCours, infosToaster)
        this.$store.dispatch('sessionuser/updateSessionusersDisponibilites', paramsValidationInvalidationGroupee)
            .then(() => {
                this.$store.dispatch('examinateur/getExaminateursDisponibilite', this.params).then(() => {
                    this.$store.dispatch('sessionuser/getSessionUserStatsvalidation').then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast(message_toast_success, succesToaster)
                        this.nbSubmitConcret = this.$store.state.sessionuser.statsValidation.disponibilites_nb_submit - this.$store.state.sessionuser.statsValidation.disponibilites_nb_valides // soumis non encore validés.
                        this.ModeInvalidation = false
                        this.showPopupValidationInvalidationGroupee = false
                    })
                })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load (fromPlanningPerso = false) {
        if(fromPlanningPerso) {
            this.chargement = true
        }
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { params: [] }).then(() => {
            if (this.$store.state.serie.series.length === 0) {
                this.$store.dispatch('serie/getSeries')
            }

            this.loadConcoursIfNotExists()

            if (this.selectedSerieId) {
                this.params.serie = this.selectedSerieId
            } else {
                this.params.serie = null
            }

            // Filtres par défaut pour premier affichage
            if (!this.params.direction) {
                this.params.direction = 'asc'
            }
            if (!this.params.sort) {
                this.params.sort = 'name'
            }

            this.$store.dispatch('examinateur/getExaminateursDisponibilite', this.params).then(() => {
                this.setFiltersForGenericTab()

                this.$store.dispatch('sessionuser/getSessionUserStatsvalidation').then(() => {
                    this.nbSubmitConcret = this.$store.state.sessionuser.statsValidation.disponibilites_nb_submit - this.$store.state.sessionuser.statsValidation.disponibilites_nb_valides // soumis non encore validés.
                    this.chargement = false
                })
            })
        })
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.$store.dispatch('matiere/getMatieres').then(() => {
                this.load()
            })
        }
    }
}

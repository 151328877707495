

























import { Vue, Component }   from 'vue-property-decorator'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'

import GestionIncompatibles from '@/views/GestionExaminateurs/gestion_incompatibles.vue'
import GestionDisponibilites from '@/views/GestionExaminateurs/GestionDisponibilites.vue'
import { BModal } from 'bootstrap-vue'

/**
 * Composant principal de la gestion des examinateurs
*/
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        GestionIncompatibles,
        GestionDisponibilites,
        'b-modal': BModal
    }
})
export default class GestionExaminateurs extends Vue
{
    onglet_select = 'dispos'
}


















































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PopupGestionIncompatibles from '@/components/GestionIncompatibles/PopupGestionIncompatibles.vue'
import PopupPublicationDesSaisiesIncompatibilites from '@/components/GestionIncompatibles/PopupPublicationDesSaisiesIncompatibilites.vue'
import { Ability } from '@/types/Ability'
import { EntityType, PosteType, getEntityTypeShortFormat } from '@/types/Poste'
import { TypePassation } from '@/types/Epreuve'
import { formatDateSinTime, checkIcone, dateNow, formatDateYYYYMMDD } from '@/utils/helpers'
import PopupEditionExaminateur from '@/components/GestionExaminateurs/PopupEditionExaminateur.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('examinateur', ['listeExaminateurs', 'isLoading', 'totalRows', 'lastPage', 'totalPage', 'selectedExaminateur']),
        ...mapState('examinateur', ['meta']),
        ...mapState('sessionuser', ['statsValidation', 'error_session_user']),
        ...mapGetters('session', ['sessions', 'sessionActive', 'sessionSelect'])
    },
    components: {
        'date-publication-incompatibilites': PopupPublicationDesSaisiesIncompatibilites,
        PopupGestionIncompatibles,
        'font-awesome-icon': FontAwesomeIcon,
        PopupEditionExaminateur,
        ExaGenericTable,
        PopUpInfoIntervenant,
        ErrorDisplay
    }
})

export default class GestionIncompatibles extends Vue {
    /* Datas = liste des examinateurs */
    datas: any                             = []
    loading                                = true
    showPopup                              = false
    showPopupDateIncompatibles             = false
    filtres: any                           = []
    examinateurSelectionne: any            = []
    Ability                                = Ability
    totalExaminateurs                      = 0
    activeSession: any                     = {}
    sessionIsOpenBoolean                   = false
    todayDate                              = Date.now()
    dataForTab: Array<any>                 = []
    selectedSerieId                        = null
    showEditExaminateur? : boolean         = false
    showPopupValidationInvalidationGroupee = false
    modeValidationGroupee                  = 'dejaSoumis'
    nbSubmitConcret                        = 0
    ModeInvalidation                       = false
    showModalInfoIntervenant               = false
    user_id_temp                           = 0
    params: any                            = {
        typePoste: PosteType.TYPE_PEDAGOGIQUE,
        typePassation: TypePassation.TYPE_PASSATION_ORAL,
        typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
        from: 'incompatibilites'
    }

    fields = [
        { key: 'actionLeft',             label: '',                            sortable: false, sortDirection: 'asc', tdClass: 'col-w-etat btn_action_ligne'       },
        { key: 'name',                   label: 'Identité',                    sortable: true,  sortDirection: 'asc', class: 'text-start ps-4', type: 'actionText' },
        { key: 'ensemble.name',          label: 'Équipe',                      sortable: false, sortDirection: 'asc', class: 'text-start ps-4'                     },
        { key: 'matiere.name',           label: 'Matière',                     sortable: false, sortDirection: 'asc', class: 'text-start ps-4'                     },
        { key: 'etat_saisie_incomp',     label: 'Saisie des incompatibilités', sortable: true,  sortDirection: 'asc', class: 'text-center'                         },
        { key: 'incompatibilites_count', label: 'Nombre Incompatibles',        sortable: true,  sortDirection: 'asc', class: 'text-start ps-4'                     }
    ]

    genericfields = [
        { key: 'etatEdit',               label: '',                            sortable: false,                       class: '',                type: 'action'     },
        { key: 'name',                   label: 'Identité',                    sortable: true,  sortDirection: 'asc', class: 'text-start ps-4', type: 'actionText' },
        { key: 'ensemble.name',          label: 'Équipe(s)',                   sortable: false,                       class: '',                type: 'text'       },
        { key: 'matiere.name',           label: 'Matière',                     sortable: false,                       class: '',                type: 'text'       },
        { key: 'etat_saisie_incomp',     label: 'Saisie des incompatibilités', sortable: true,                        class: 'text-center',     type: 'text'       },
        { key: 'incompatibilites_count', label: 'Nombre Incompatibles',        sortable: true,                        class: 'text-center',     type: 'text'       }
    ]

    @Watch('isLoading')
    toggleLoading(): void {
        this.loading = this.$store.getters['examinateur/isLoading']
    }

    @Watch('listeExaminateurs')
    getExaminateurs(): void {
        this.initDatas()
    }

    /**
     * @description Recuperation de l'examinateur selectionné par l'administration
     * @returns {void}
     */
    @Watch('selectedExaminateur')
    getSelectedExaminateur(): void {
        this.examinateurSelectionne = this.$store.getters['examinateur/selectedExaminateur']
    }

    @Watch('sessionSelect')
    watchSession(): void {
        this.activeSession = this.$store.getters['session/sessionSelect']
        this.sessionIsOpenBoolean = this.sessionIsOpen()
    }

    @Watch('meta')
    majInfosTable(): void {
        // On récupère le nombre total de dossiers filtrés depuis les infos des Metadonnées
        if (this.$store.state.examinateur.meta !== null && this.$store.state.examinateur.meta !== undefined) {
            if (this.totalExaminateurs !== this.$store.state.examinateur.meta.total) {
                this.totalExaminateurs = this.$store.state.examinateur.meta.total
            }
        } else {
            this.totalExaminateurs = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface(): void {
        this.load()
    }

    /**
     * @description Formattage des dates
     * @param {Date} date - Date à formater
     * @returns {string} - Date formatée
     */
    formatTheDate(date: Date): string {
        return formatDateSinTime(date)
    }

    buildFiltre(): void {
        if (this.$store.getters['matiere/matieres'].length === 0) {
            this.$store.dispatch('matiere/getMatieres')
        }

        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        this.filtres = [
            { libelle: 'Nom',     defautOptionlibelle: 'Rechercher un',  model: 'name',          value: '', index: '',               datas: '',               loading: false, options: { type: 'form',      fieldsKey: 'name'                        } },
            { libelle: 'Équipe',  defautOptionlibelle: 'Rechercher une', model: 'ensemble_name', value: '', index: 'ensembles.name', datas: null,             loading: false, options: { type: 'form',      fieldsKey: 'ensemble.name', strict: true } },
            { libelle: 'Matière', defautOptionlibelle: 'Rechercher une', model: 'matiere_id',    value: '', index: 'epreuve.name',   datas: options_matieres, loading: false, options: { type: 'deroulant', fieldsKey: 'matiere.name'                } }
        ]
    }

    loadHandler(params: any): void {
        for (const p in params) {
            this.params[p] = params[p]
        }
        this.$store.dispatch('examinateur/getMoreExaminateurs', this.params)
            .then(() => this.initDatas())
            .catch((error: any) => console.log('error : ' + error))
    }

    filtreSortHandler(params: any): void {
        // Clean les params
        this.params = {
            typePoste: PosteType.TYPE_PEDAGOGIQUE,
            typePassation: TypePassation.TYPE_PASSATION_ORAL,
            typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
            from: 'incompatibilites'
        }

        for (const p in params) {
            this.params[p] = params[p]
        }
        // Des filtres sont appliqués on rappele le serveur
        this.$store.state.examinateur.listeExaminateurs = []
        this.$store.dispatch('examinateur/getExaminateurs', this.params)
            .then(() => this.initDatas())
            .catch((error: any) => console.log('error : ' + error))
    }

    /**
     * @description Déclenchement du popup lors du clic sur la premiere colonne, selection de l'examinateur
     * @param {any} row - Ligne selectionnée
     * @returns {void}
     */
    consulterExaminateur(row: any): void {
        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.$store.commit('examinateur/SET_EXAMINATEUR_ID', row.id)
        this.$store.commit('candidatIncompatible/SET_EXAMINATEUR_SELECTIONNE', row)
        this.showPopup = true
    }

    /**
     * @description Gestion affichage de la popupDate
     * @returns {void}
     */
    togglePopupDateIncompatibles(): void {
        this.showPopupDateIncompatibles = false
    }

    /**
     * @description Retourne une classe css selon l'état de validation des infos
     * @returns {string} - Classe css
     */
    getClassBarreBoutons(): string {
        if (this.activeSession &&  this.activeSession.incompatibilites_start_at && !this.sessionIsOpenBoolean) {
            return 'barre_soumis'
        } else if (!this.sessionIsOpenBoolean) {
            return 'barre_rejet'
        } else if (this.sessionIsOpenBoolean) {
            return 'barre_valide'
        }
        return 'barre_default'
    }

    /**
     * @description Contrôle si la date du jour et est bien entre les deux dates d'ouverture de la saisie des incompat.
     * @returns {boolean}
     */
    sessionIsOpen(): boolean {
        const todayDate = dateNow()
        if (this.activeSession && this.activeSession.incompatibilites_start_at === null && this.activeSession.incompatibilites_end_at === null) {
            return false
        }
        if (this.activeSession && this.activeSession.incompatibilites_start_at && this.activeSession.incompatibilites_end_at && todayDate) {
            const todayDateFormatted = formatDateYYYYMMDD(todayDate)
            const sessionEndDateFormatted = formatDateYYYYMMDD(this.activeSession.incompatibilites_end_at)
            return this.activeSession.incompatibilites_start_at <= todayDate && todayDateFormatted <= sessionEndDateFormatted
        } else if (this.activeSession && this.activeSession.incompatibilites_start_at === null && this.activeSession.incompatibilites_end_at && todayDate) {
            const todayDateFormatted = formatDateYYYYMMDD(todayDate)
            const sessionEndDateFormatted = formatDateYYYYMMDD(this.activeSession.incompatibilites_end_at)
            return todayDateFormatted <= sessionEndDateFormatted
        }
        return false
    }

    /**
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        const can = this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE)
        const icone = checkIcone(Ability.ORAL_PREPA_MANAGE, can)

        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            this.dataForTab = []
            for (const result of poData) {
                const name = result.name ? result.name : '-'
                const first_name = result.first_name ? result.first_name : '-'

                let ensembles = ''
                if (result.ensembles) {
                    ensembles = result.ensembles
                        .map((ensemble: any) => ensemble.name)
                        .join(', ')
                } else if (result.ensemble) {
                    ensembles = result.ensemble ? result.ensemble.name : '-'
                }
                const matiere = result.matiere ? result.matiere.name : '-'
                const nb_incompatibles = result.incompatibilites_count ? result.incompatibilites_count : 0

                const puce_saisie_examinateur = []
                let class_saisie_examinateur = 'text-tertiary'
                if (result.infosessionuser) {
                    switch (true) {
                        case result.infosessionuser.incompatibilites_validated_at !== null:
                            class_saisie_examinateur = 'text-success'
                            break
                        case result.infosessionuser.incompatibilites_submitted_at !== null:
                            class_saisie_examinateur = 'text-info'
                            break
                        case result.infosessionuser.commentaire_rejet !== null:
                            if (result.infosessionuser.commentaire_rejet.incompatibilites) {
                                class_saisie_examinateur = 'text-danger'
                            }
                            break
                    }
                }
                puce_saisie_examinateur.push({ name:'circle', class: class_saisie_examinateur })
                // Puce ajustement
                const puce_ajustements = []
                let class_ajustement_examinateur = 'text-tertiary'
                if (result.infosessionuser) {
                    switch (true) {
                        case result.infosessionuser.ajustements_validated_at !== null:
                            class_ajustement_examinateur = 'text-success'
                            break
                    }
                }
                puce_ajustements.push({ name:'circle', class: class_ajustement_examinateur })
                const line = [
                    { label: icone.label, item: result.id               , type: 'action'    , typeAction: 'edit'           , class: 'commons_first_action_button', icon:icone.icon               , disabled: false },
                    { label: ''         , item: (result ? result.id : 0), type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action'      , text: name + ' ' + first_name                   },
                    { label: ''         , item: ensembles               , type: 'text'      , typeAction: null             , class: ''                                                                             },
                    { label: ''         , item: matiere                 , type: 'text'      , typeAction: null             , class: ''                                                                             },
                    { label: ''         , item: puce_saisie_examinateur , type: 'icons'     , typeAction: null             , class: 'text-center'                                                                  },
                    { label: ''         , item: nb_incompatibles        , type: 'text'      , typeAction: null             , class: 'text-center'                                                                  }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Récupération des events du tableau (params[0] => action, params[1] => id de l'item)
     * @param {any} paParams - Paramètres de l'event
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const examinateurs_dispos = this.$store.state.examinateur.listeExaminateurs
            let selectedExaminateur = null
            switch (paParams[0]) {
                case 'edit':
                    selectedExaminateur = examinateurs_dispos.filter((examinateur: any) => examinateur.id === paParams[1])[0]
                    if (selectedExaminateur) {
                        this.consulterExaminateur(selectedExaminateur)
                    }
                    break

                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /**
     * @description Initialisation des données examinateurs affichées dans le tableau ainsi que les compteurs
     * @returns {void}
     */
    initDatas() {
        this.setDataForGenericTab(this.$store.getters['examinateur/listeExaminateurs'])
    }

    /**
     * @description Ouvre la fenêtre de validation groupée des incompatibilités
     * @returns {void}
     */
    openPopupValidationGroupee(): void {
        this.$store.commit('sessionuser/SET_ERROR', null) // reset de l'erreur potentiellement existante
        this.ModeInvalidation = false
        this.$store.dispatch('sessionuser/getSessionUserStatsvalidation').then(() => {
            this.nbSubmitConcret = this.$store.state.sessionuser.statsValidation.incompatibilites_nb_submit - this.$store.state.sessionuser.statsValidation.incompatibilites_nb_valides // soumis non encore validés.
            this.modeValidationGroupee = this.nbSubmitConcret > 0 ? 'dejaSoumis' : 'tous'
            this.showPopupValidationInvalidationGroupee = true
        })
    }

    /**
     * @description Ouvre la fenêtre d'invalidation groupée des incompatibilités
     * @returns {void}
     */
    openPopupInvalidationGroupee(): void {
        this.$store.commit('sessionuser/SET_ERROR', null) // reset de l'erreur potentiellement existante
        this.ModeInvalidation = true
        this.showPopupValidationInvalidationGroupee = true
    }


    /**
     * @description Ferme / Annule la fenêtre de validation groupée des incompatibilités
     * @returns {void}
     */
    closePopupValidationInvalidationGroupee(): void {
        this.showPopupValidationInvalidationGroupee = false
    }

    /**
     * @description Confirmation de la validation groupée
     * @returns {void}
     */
    confirmValidationInvalidationGroupee(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        let paramsValidationInvalidationGroupee = {}
        if (this.ModeInvalidation) {
            // Cas de l'invalidation
            paramsValidationInvalidationGroupee = {
                validate: 0,
                submitted: 0
            }
        } else {
            // Cas de la validation
            switch (this.modeValidationGroupee) {
                case 'dejaSoumis':
                    // Cas de la validation pour les examinateurs ayant soumis
                    paramsValidationInvalidationGroupee = {
                        validate: 1,
                        submitted: 1
                    }
                    break
                case 'tous':
                    // Cas de la validation pour tous les examinateurs
                    paramsValidationInvalidationGroupee = {
                        validate: 1,
                        submitted: 0
                    }
                    break
            }
        }

        const message_toast_enCours = this.ModeInvalidation ? 'Invalidation en cours ...' : 'Validation en cours ...'
        const message_toast_success = this.ModeInvalidation ? 'Les incompatibilités des examinateurs ont été invalidées !' : 'Les incompatibilités des examinateurs ont été validées !'
        this.$bvToast.toast(message_toast_enCours, infosToaster)
        this.$store.dispatch('sessionuser/updateSessionusersIncompatibilites', paramsValidationInvalidationGroupee)
            .then(async () => {
                await this.$store.dispatch('examinateur/getExaminateurs', this.params)
                await this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(message_toast_success, succesToaster)
                this.nbSubmitConcret = this.$store.state.sessionuser.statsValidation.incompatibilites_nb_submit - this.$store.state.sessionuser.statsValidation.incompatibilites_nb_valides // soumis non encore validés.
                this.modeValidationGroupee = this.nbSubmitConcret > 0 ? 'dejaSoumis' : 'tous'
                this.ModeInvalidation = false
                this.showPopupValidationInvalidationGroupee = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load(): void {
        this.buildFiltre()
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.$store.state.session.sessionSelect.id, params: [] })
            .then(async () => {
                if (this.$store.state.serie.series.length === 0) {
                    await this.$store.dispatch('serie/getSeries', { 'filter-session_id': this.$store.state.session.sessionSelect.id })
                }

                // Filtres par défaut pour premier affichage
                if (!this.params.direction) {
                    this.params.direction = 'asc'
                }
                if (!this.params.sort) {
                    this.params.sort = 'name'
                }

                await this.$store.dispatch('examinateur/getExaminateurs', this.params)
                await this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
                this.nbSubmitConcret = this.$store.state.sessionuser.statsValidation.incompatibilites_nb_submit - this.$store.state.sessionuser.statsValidation.incompatibilites_nb_valides // soumis non encore validés.
                this.modeValidationGroupee = this.nbSubmitConcret > 0 ? 'dejaSoumis' : 'tous'
            })
    }

    beforeMount(): void {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}


































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isObject } from '@/utils/helpers'
import { TypePassation } from '@/types/Epreuve'
import PopupGestionIncompatibles from '@/components/GestionIncompatibles/PopupGestionIncompatibles.vue'
import GestionDisponibilites from '@/components/GestionExaminateurs/Disponibilites/GestionDisponibilites.vue'
import InformationsExaminateur from '@/components/GestionExaminateurs/Disponibilites/InformationsExaminateur.vue'
import { TypeEnsemble } from '@/types/Ensemble'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { EntityType, getEntityTypeShortFormat, PosteType } from '@/types/Poste'
import { BModal } from 'bootstrap-vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('session', ['sessions', 'sessionSelect']),
        ...mapState('disponibilite', ['error_dispos']),
        ...mapGetters('disponibilite', ['get_priorite_next', 'get_priorite_total']),
        ...mapGetters('examinateur', ['examinateur_select', 'examinateur_select_infos'])
    },
    components: {
        'b-modal': BModal,
        'font-awesome-icon': FontAwesomeIcon,
        PopupGestionIncompatibles,
        GestionDisponibilites,
        InformationsExaminateur,
        ErrorDisplay
    }
})

export default class PopupEditionExaminateur extends Vue {
    @Prop() openModal? : boolean
    @Prop() context? : string
    @Prop() serie_id? : number
    @Prop() chargement? : boolean

    selected_tab_pop = 'disponibilites'
    lockActionsButtons?: boolean = false
    TypePassation = TypePassation
    epreuve_correction = null
    type_ensemble = null
    TypeEnsemble = TypeEnsemble
    chaine_concours = ''

    @Watch('openModal', { immediate: true, deep: true })
    changeContext(): void {
        if (this.$props.openModal) {
            this.selected_tab_pop = this.$props.context
        }
    }

    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        await this.load()
    }

    refresh(fromPlanningPerso = false): void {
        this.$emit('refresh', fromPlanningPerso)
    }

    getExaminateurMatieres(examinateur_select: any): string {
        return this.$store.getters['examinateur/get_examinateur_matiere_ids'](examinateur_select)
            .map((id: number) => this.$store.getters['matiere/getMatiereById'](id).name)
            .filter((name: string, index: number, self: string[]) => self.indexOf(name) === index)
            .join(', ')
    }

    /**
     * @description Fermeture du popup de publication
     * @returns {void}
     */
    closeModal(): void {
        // Mise à jour de l'examinateur en cours d'édition dans la collection pour maj les compteurs
        // Puis fermeture du popup
        const params: any =     {
            typePoste: PosteType.TYPE_PEDAGOGIQUE,
            typePassation: TypePassation.TYPE_PASSATION_ORAL,
            typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
            from: this.$props.context
        }
        if (this.$props.serie_id) {
            params.serie = this.$props.serie_id
        }

        params['filter-id'] = this.$store.getters['examinateur/examinateur_select_infos'].id
        this.$store.dispatch('examinateur/getExaminateurDisponibilite', params).then(() => {
            this.$store.state.disponibilite.error_dispos = null
            this.selected_tab_pop = ''
            this.$emit('close')
        })
    }

    /**
     * @description Check si l'erreur récupérée est un objet
     * @param {any} value - Valeur à tester
     * @returns {boolean}
     */
    isObjectLocal(value: any): boolean {
        return isObject(value)
    }

    /**
     * @description Initialisation des données
     * @returns {void}
     */
    initDatas(): void {
        // Reinit error
        this.$store.state.disponibilite.error_dispos = null
        this.chaine_concours = this.$store.getters['banque/banques']
            ?.map((banque: any) => banque.code)
            .join(', ')
    }

    getNamesEnsembles(ensembles: Array<any>): string {
        let chaine_ensembles = ''
        const done: Array<number> = []
        if (ensembles) {
            for (let i = 0; i < ensembles.length;  i++) {
                if (!done.includes(ensembles[i].id)) {
                    if (chaine_ensembles !== '') {
                        chaine_ensembles += ', '
                    }
                    chaine_ensembles += ensembles[i].name
                    done.push(ensembles[i].id)
                }
            }
        }

        if (done.length > 1) {
            return 'Équipes ' + chaine_ensembles
        } else {
            return 'Équipe ' + chaine_ensembles
        }
    }

    /**
     * @description Retourne une chaine indiquant les filières des ensembles ciblés
     * @param {Array<any>} ensembles - Liste des ensembles
     * @returns {string}
     */
    getConcoursEnsemble(ensembles: Array<any>): string {
        let chaine_concours = ''
        const done: Array<number> = []
        if (ensembles) {
            for (let i = 0; i < ensembles.length;  i++) {
                if (ensembles[i].concour) {
                    if (!done.includes(ensembles[i].concour.id)) {
                        if (chaine_concours !== '') {
                            chaine_concours += ', '
                        }
                        chaine_concours += ensembles[i].concour.name
                        done.push(ensembles[i].concour.id)
                    }
                }
            }
        } else {
            chaine_concours = this.chaine_concours
        }

        return chaine_concours
    }

    async load(): Promise<void> {
        await this.$store.dispatch('banque/getBanques', { isPrecedente: false })
        this.initDatas()
    }

    async beforeMount(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}

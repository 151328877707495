














































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Semainier from '@/components/GestionExaminateurs/Disponibilites/Semainier.vue'
import { TypeEnsemble } from '@/types/Ensemble'
import { format_phone_number } from '@/utils/helpers'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('examinateur', ['examinateur_select_infos']),
        ...mapState('epreuve', ['epreuvesCorrections'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Semainier
    }
})

export default class InformationsExaminateur extends Vue {
    examinateur_current: any = null
    epreuves = ''
    filiere = ''
    matieres: any[] = []
    centres: any[] = []
    formatPhoneNumber = format_phone_number

    /**
     * @description Récupère les noms des ensembles de l'examinateur
     * @returns {Array<any>} - Noms des ensembles
     */
    get examinateurEnsemblesName(): Array<any> {
        const ensembleNames =  this.examinateur_current?.ensembles?.map((e: any) => e.name)
        return [...new Set(ensembleNames)] // filter duplicates
    }

    /**
     * @description Récupère les noms des matières de l'examinateur
     * @param {number[]} matieresId - Ids des matières
     * @returns {Promise<any>} - Noms des matières
     */
    async fetchMatieres(matieresId: number[]): Promise<any> {
        const queriesMatieres = []
        matieresId = [...new Set(matieresId)] // filter duplicates
        for (const id of matieresId) {
            queriesMatieres.push(Promise.resolve(this.$store.dispatch('matiere/getMatiere', { matiere_id: id })))
        }
        const responses = await Promise.all(queriesMatieres)
        return responses.map(r => r.data.data.name)
    }

    /**
     * @description Récupère les noms des centres de l'examinateur
     * @param {number[]} centersId - Ids des centres
     * @returns {Promise<any>} - Noms des centres
     */
    async fetchCentersName(centersId: number[]): Promise<any> {
        const queriesCenter = []
        centersId = [...new Set(centersId)] // filter duplicates
        for (const id of centersId) {
            queriesCenter.push(Promise.resolve(this.$store.dispatch('centre/getCentre', { centre_id: id })))
        }
        const responses = await Promise.all(queriesCenter)
        return responses.map(r => `${r.data.data.name} - ${r.data.data.ville.name}`)
    }

    /**
     * @description Chargement des informations
     * @returns {void}
     */
    initDatas(): void {
        this.examinateur_current = this.$store.getters['examinateur/examinateur_select_infos']
        this.epreuves = ''
        this.filiere = ''

        // Centres
        if (this.examinateur_current.ensembles?.length) {
            const centersId = this.examinateur_current.ensembles.map((e: any) => e.centre_id)
            this.fetchCentersName(centersId).then((centersName) => {
                this.centres = centersName
            })
        }

        // Matières
        if (this.examinateur_current.affectations?.length !== 0 &&
            this.examinateur_current.ensembles?.length) {
            const matieresId = []
            for (const ensemble of this.examinateur_current.ensembles) {
                if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    const epreuve_correction_id_examinateur = this.examinateur_current.affectations[0].epreuve_correction_id
                    const index = this.$store.state.epreuve.epreuvesCorrections.findIndex((ep: any) => {
                        return ep.id === epreuve_correction_id_examinateur
                    })
                    this.filiere = ensemble.concour.name
                    if (this.$store.state.epreuve.epreuvesCorrections[index]) {
                        this.epreuves = ensemble.concour.name + '-' + this.$store.state.epreuve.epreuvesCorrections[index].name
                        matieresId.push(this.$store.state.epreuve.epreuvesCorrections[index].matiere_id)
                    }
                } else {
                    for (let i = 0; i < ensemble.groupeEpreuve.epreuves.length; i++) {
                        for (let j = 0; j < ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                            const concTemp = this.$store.getters['concour/concourById'](ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id)
                            if (concTemp) {
                                if (this.epreuves.length > 0) {
                                    this.epreuves += ', '
                                }
                                if (this.filiere.length > 0) {
                                    this.filiere += ', '
                                }
                                const concourId = ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id
                                const codeConcours = this.$store.getters['concour/concourById'](concourId).code
                                this.filiere += codeConcours
                                this.epreuves +=  codeConcours + '-' + ensemble.groupeEpreuve.epreuves[i].epreuves[j].name
                                matieresId.push(ensemble.groupeEpreuve.epreuves[i].epreuves[j].matiere_id)
                            }
                        }
                    }
                }
            }
            this.fetchMatieres(matieresId).then((matieres) => {
                this.matieres = matieres
            })
        }
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        if (this.$store.state.epreuve.epreuvesCorrections.length !== 0) {
            await this.$store.dispatch('epreuve/getEpreuvesCorrections', { params: [] })
        }
        this.initDatas()
    }
}
